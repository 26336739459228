.App {
}

/* FONTS */

@font-face {
  font-family: "apophenia";
  src: url("fonts/Apophenia_LogoVariable.ttf") format("truetype");
}

@font-face {
  font-family: Programme-Primitiv;
  src: url("fonts/Programme-Primitiv.otf") format("opentype");
}

a {
  font-family: Programme-Primitiv, sans-serif;
  color: white;
  text-align: center;
  font-size: 70px;
}

p {
  font-family: apophenia, sans-serif;
  color: white;
  cursor: default;
}

/* ANIMATION */

@keyframes logo-transition {
  0% {
    font-variation-settings: "wght" 900;
    opacity: 1;
  }
  25% {
    font-variation-settings: "wght" 900;
  }
  31% {
    font-variation-settings: "wght" 400;
  }
  84% {
    font-variation-settings: "wght" 400;
  }
  90% {
    font-variation-settings: "wght" 900;
  }
  95% {
    font-variation-settings: "wght" 900;
    opacity: 1;
  }
  100% {
    font-variation-settings: "wght" 900;
    opacity: 0;
  }
}

.vimeo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 55px;
  padding-top: 10px;
  display: block;
  mix-blend-mode: overlay;
  position: absolute;
  left: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .logo {
    width: 50px;
    padding-top: 10px;
    display: block;
    mix-blend-mode: overlay;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
}

.variable-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -61%);
  z-index: 10000;
  mix-blend-mode: overlay;
  font-family: "apophenia";
  font-size: 1200px;
  animation: logo-transition 18s forwards;
}

@media only screen and (max-width: 768px) {
  .variable-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -67%);
    z-index: 10000;
    mix-blend-mode: overlay;
    font-family: "apophenia";
    font-size: 360px;
    line-height: 0px;
    animation: logo-transition 18s forwards;
  }
}
